import React, { useEffect } from "react";

export default function HomePage() {
  useEffect(() => {
    const last = document.getElementsByClassName("inverse")[0];
    if (last) {
      last.classList.remove("inverse");
    }
  }, []);

  return (
    <div className="text-white sm:px-0 md:px-4 lg:px-0">
      <h1 className="pb-2">Welcome to ETRA</h1>
      <p className="mb-0">
        ETRA is a collective of artists writing about and curating music by the
        creatives of the future.
      </p>
      <p>
        Follow us on{" "}
        <a href="https://instagram.com/etra.live" target="_blank">
          Instagram
        </a>{" "}
        to stay updated, or <a href="mailto:info@etra.live">email us</a> if you
        want to chat.
      </p>
    </div>
  );
}
