import React from "react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { hydrate, render } from "react-dom";
import ScrollToTop from "./components/ScrollToTop.js";
import "./index.css";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>,
    document.querySelector("#root")
  );
} else {
  render(
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>,
    document.querySelector("#root")
  );
}
