import React, { useEffect } from "react";
import posts from "../posts.json";
import { Navigate, Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import LazyLoad from "react-lazyload";
import { DuotoneImage } from "react-duotone";

export default function Post() {
  const { slug, authorSlug } = useParams();
  var givenSlug = authorSlug + "/" + slug;
  var foundMatch = false;
  var post;

  useEffect(() => {
    const last = document.getElementsByClassName("inverse")[0];
    if (last) {
      last.classList.remove("inverse");
    }
    document.documentElement.style.setProperty("--main-bg", post.color);
    document.getElementById(givenSlug).classList.add("inverse");
  }, []);

  for (var element of posts) {
    if (givenSlug === element.slug) {
      foundMatch = true;
      post = element;
      break;
    }
  }

  if (!foundMatch) {
    return <Navigate to={{ pathame: "/" }} />;
  }

  var tempDate = new Date(post.timestamp * 1000);
  var date = tempDate.toString();

  const schemaJson = {
    "@context": "https://schema.org",
    "@type": "Newsalbum",
    headline: "Review: " + post.title + " by " + post.author,
    image: [post.jpg.substring(7)],
    datePublished: date,
    dateModified: date,
    author: [
      {
        "@type": "Organization",
        name: "ETRA",
        url: "https://etra.live",
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>{post.title} | ETRA</title>
        <meta
          property="og:title"
          name="twitter:title"
          content={post.title + " | ETRA"}
        />
        <meta
          property="og:image"
          name="twitter:image"
          content={"https://etra.live" + post.jpg.substring(7)}
        />
        <meta name="twitter:image:alt" content={post.title + "Cover"} />
      </Helmet>
      {post.type === "mix" && (
        <Helmet>
          <meta
            name="description"
            content={
              "Read the tracklist and listen to the mix archive of " +
              post.title +
              " on ETRA."
            }
          />
          <meta
            property="og:description"
            name="twitter:description"
            content={
              "Read the tracklist and listen to the mix archive of " +
              post.title +
              " on ETRA."
            }
          />
        </Helmet>
      )}
      {post.type === "album" && (
        <Helmet>
          <meta
            name="description"
            content={
              "Read and listen to " +
              post.title +
              " by " +
              post.author +
              " on ETRA."
            }
          />
          <meta
            property="og:description"
            name="twitter:description"
            content={
              "Read and listen to " +
              post.title +
              " by " +
              post.author +
              " on ETRA."
            }
          />
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </Helmet>
      )}
      <div className="text-white pt-2 sm:pt-4 mx-5 sm:mx-0">
        <div className="article-photo lg:mr-4 ">
          <LazyLoad height={"100%"}>
            <DuotoneImage
              src={post.jpg.substring(7)}
              alt={post.alt}
              className="pb-3 article-photo mx-auto sm:pt-1 w-full "
              primaryColor="#FFFFFF"
              secondaryColor={post.color}
            />
          </LazyLoad>
        </div>
        <div className="text-center sm:text-left">
          <h1
            className="inline"
            dangerouslySetInnerHTML={{ __html: post.title }}
          />
          <p className="sm:inline sm:ml-2 whitespace-nowrap">
            by: {post.author}
          </p>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html:
              "<p>" +
              post.content
                .replace(/(?:\n\n)/g, "</p> <p>")
                .replace(/\*([^*]+?)\*/g, "<i>$1</i>") +
              "</p>",
          }}
        />
      </div>
    </>
  );
}
