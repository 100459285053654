import React from "react";
import { Link } from "react-router-dom";

export default function PostListEntry(props) {
  const d = new Date(props.postData.date);

  return (
    <div>
      <Link to={props.postData.slug} className={props.postData.type}>
        <div
          id={props.postData.slug}
          className={`my-1 py-2 mx-5 sm:mx-1 flex text-white`}
        >
          <div className="flex items-center mr-2 rotate-180">
            <h6 className="postlist-label m-0">{props.postData.type}</h6>
          </div>
          <div className="w-full">
            <div className="flex w-full justify-between">
              <div
                className="archivo"
                dangerouslySetInnerHTML={{ __html: props.postData.title }}
              ></div>
              <div className="pr-3">
                {d.getMonth() + "." + d.getDay() + "." + d.getFullYear()}
              </div>
            </div>
            <div className="archivo">{props.postData.author}</div>
          </div>
        </div>
        <div className="mx-5 sm:mx-1">
          <hr />
        </div>
      </Link>
    </div>
  );
}
